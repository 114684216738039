import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { combineLatest, map, of, Subject, switchMap, take, throwError } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";

import { GAnalyticsService } from "@common-services/google-analitics.service";
import { ModalWindowService } from "@common-services/modal-window.service";
import { MyPredictionsService } from "@common-services/my-predictions.service";
import { NavigationService } from "@common-services/navigation.service";
import { PrizeAmountService } from "@common-services/prize-amount.service";
import { StreakService } from "@common-services/streak.service";
import { TenantService } from "@common-services/tenant.services";
import { BaseButtonComponent } from "@components/base-button/base-button.component";
import { TimerComponent } from "@components/timer/timer.component";
import { GameStatusEnum } from "@enums/GameStatusEnum";
import { HistoricalAnswerStatusEnum } from "@enums/HistoricalAnswerStatusEnum";
import { RoundResultStatus } from "@enums/RoundResultEnum";
import { StreakDetailModel } from "@models/StreakDetailModel";
import { FormatThousandsNumberPipe } from "@modules/pipes/pipes/format-number.pipe";
import { CmsContentService } from "@services/cms-content.service";
import { GameService } from "@services/game.service";
import { QuestionService } from "@services/question.service";
import { ValidTicketService } from "@services/valid-ticket.service";

@Component({
    selector: 'hun-landing-streak-round-info',
    templateUrl: 'landing-streak-round-info.component.html',
    styleUrls: ['landing-streak-round-info.component.scss'],
    standalone: true,
    imports: [
        BaseButtonComponent,
        TimerComponent,
        AsyncPipe,
        FormatThousandsNumberPipe,
    ],
})
export class LandingStreakRoundInfoComponent {

  @Input() roundInfo: StreakDetailModel | null;

  protected readonly GameStatus = GameStatusEnum;

	public unsubscribe$ = new Subject<void>();

	constructor(
    public cmsContentService: CmsContentService,
    public gameService: GameService,
    public tenantService: TenantService,
    public prizeAmountService: PrizeAmountService,
		private questionService: QuestionService,
		private router: Router,
		private streakService: StreakService,
		private navigationService: NavigationService,
		private gAnalyticsService: GAnalyticsService,
		private validTicketService: ValidTicketService,
		private modalWindowService: ModalWindowService,
		private myPredictionsService: MyPredictionsService
	)
	{}


	onRoundClick() {
		this.streakService.getActiveStreak$().pipe(
			filter(res => !!res),
			switchMap((res) => {
				return combineLatest([
					of(res),
					this.validTicketService.getRestrictedSegmentUser(res.streakId),
				])
			}),
			catchError((err) => {
				this.modalWindowService.showRestrictedModal(err.error?.statusCode, true);
				return throwError(err);
			}),
      take(1)
		).subscribe(([res, ]) => {
			if (res && res.isOpened && !res.showStreakLvlResetMessage) {
        this.gameService.reloadQuestionList_v3(res.roundId).pipe(
          switchMap(() => this.questionService.roundsQuestionSet$.pipe(take(1))),
          tap(() => this.gameService.currentRound$.next(res.roundId)),
          map(() => {
						if (res.hasPlayed) {
              this.router.navigate([`frame/streak/my-picks`]);
            } else {
							this.myPredictionsService.onCurrentRoundChange();
							this.gAnalyticsService.toPlay({ roundID: res.streakId });
              this.router.navigate([`frame/streak/game/${res.roundId}`]);
            }
          }),
          take(1)
        ).subscribe();
			} else if (res.isComplete && !res.answersReviewed && res.resulted) {
				if (res.showStreakLvlResetMessage && res.answersReviewed
					|| (res.showStreakLvlResetMessage && res.resultStatus === HistoricalAnswerStatusEnum.Correct)) {
					this.streakService.setRoundStatus(RoundResultStatus.LOSE_PROGRESS)
				} else if (res.resultStatus === HistoricalAnswerStatusEnum.Incorrect) {
					this.streakService.setRoundStatus(RoundResultStatus.LOSS)
				} else if (res.resultStatus === HistoricalAnswerStatusEnum.Correct) {
					if (res.isJackpot) {
						this.streakService.setRoundStatus(RoundResultStatus.JACKPOT)
					} else {
						this.streakService.setRoundStatus(RoundResultStatus.WINN)
					}
				} else if (res.resultStatus === HistoricalAnswerStatusEnum.NoCorrectAnswer) {
					this.streakService.setRoundStatus(RoundResultStatus.VOIDED_QUESTION)
				}
				this.navigationService.setInternalNavigation(true);
				this.router.navigate(['/frame/streak/view-results'])
			} else if (!res.resulted && res.isClosed && !res.answersReviewed) {
				this.gameService.currentRound$.next(res.roundId);
				this.router.navigate(['frame/streak/my-picks']);
			}
    })
  }

	getFontSize() {
		const length = this.prizeAmountService.totalPrizeSumLength;
		return length <= 12 ? '50px'  : `${300 / length * 1.9}px`;
	}
}
