import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from "lodash";

@Pipe({
    name: 'formatThousandsNumber',
    standalone: true
})
export class FormatThousandsNumberPipe implements PipeTransform {

  transform(number: string | number, separator = ','): string  | null {
    if (isNull(number)) return null;

    const numberToString = number.toString()
    const [integerPart, decimalPart = ''] = numberToString.split('.');
    return `${this.formatThousands(integerPart, separator)}${decimalPart ? `.${decimalPart}` : ''}`;
  }

  formatThousands(number, separator) {
    if (number.length <= 3) {
      return number;
    } else {
      const formattedSubstr = this.formatThousands(number.slice(0, -3), separator);
      return formattedSubstr + separator + number.slice(-3);
    }
  }
}
