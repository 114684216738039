@if (cmsContentService.cmsContent$ | async; as cmsContent) {
  <div class="streak-round-info-wrapper"
    >
    <div class="header-message">
      {{ cmsContent.text_content['iframe-v3-streak-header-message-start-word']
      + ' ' + roundInfo.correctAnswersNeeded + ' ' + cmsContent.text_content['iframe-v3-streak-header-message-end-word']}}
    </div>
    @if ((this.prizeAmountService.getSinglePrizeAmount(
      (roundInfo.prize | formatThousandsNumber:(cmsContent.text_content['iframe-v3-prize-amount-separator'])),
      cmsContent['text_content']['iframe-v3-streak-currency-prize'],
      true,
      cmsContent.text_content['iframe-v3-streak-currency-mark'])
      | async); as prize) {
      <div
        class="prize-section"
        [style.font-size]="getFontSize()">
        {{prize}}
      </div>
    }
    <div class="explain-massage">
      {{ cmsContent.text_content['iframe-v3-streak-round-info-explain'] }}
    </div>
    <hun-base-button
      class="game-button"
      [disabled]="gameService.isStreakGameClosed(roundInfo) || gameService.isStreakGameNotOpened(roundInfo)"
      [bgColor]="roundInfo.gameButtonColor"
      [color]="roundInfo.gameButtonTextColor"
      [fontSize]="'16px'"
      [lineHeight]="'20px'"
      (handleClick)="onRoundClick()"
      >
      <div class="button-content">
        @if (gameService.isGameOpenAndPlayed(roundInfo.currentRoundData) && !gameService.isStreakGameClosed(roundInfo)) {
          <img [src]="cmsContent['image_content']['iframe-v3-streak-landing-edit-button']">
        }
        {{roundInfo.gameButtonText}}
      </div>
    </hun-base-button>
    <div class="timer-round-wrapper">
      <img [src]="cmsContent['image_content']['iframe-v3-streak-timer-icon-landing']">
      @switch (roundInfo.status) {
        @case (GameStatus.OPENED) {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-streak-landing-timer-explanation']}}</div>
          <hun-timer [date]="roundInfo.closeDate" [isCloseTime]="true" [showMinutes]="true"></hun-timer>
        }
        @case (GameStatus.PENDING) {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-streak-landing-timer-pending-game-explanation']}}</div>
          <hun-timer [date]="roundInfo.openDate" [showMinutes]="true"></hun-timer>
        }
        @default {
          <div class="timer-explanation">{{cmsContent['text_content']['iframe-v3-streak-timer-round-close-message']}}</div>
        }
      }
    </div>
  </div>
}
